@tailwind base;
@tailwind components;
@tailwind utilities;

$font-stack: Helvetica, sans-serif;
$primary-color: #fff;
$theme-color:#facc15;

body {
  background-color: $primary-color;
  font-family: $font-stack;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
li {
  list-style-type: none;
}
.menu-item a {
  position: relative;
  display: block;
  color: #545050;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo {
  width: auto;
  display: inline-block;
  padding-left: 2%;
}
.header__middle__menus {
  width: 80%;
  display: inline-block;
  // padding-left: 40%;
}
.menu-item.menu__qqqitem a {
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  position: relative;
}
.header__middle__logo > a > h1 {
  width: 40%;
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
.active {
  color: $theme-color !important;
}
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: $theme-color;
}
.sub__menus__arrows {
  position: relative;
}
.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  bottom: 14%;
  left: 100%;
  margin-left: 4%;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  width: 17rem;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}
@media (max-width: 767px) {
  .header__middle .active {
    color: $theme-color !important;
  }
}
/* for web view */
@media (min-width: 992px) {
  /* header */
  .header {
    background: white;
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 1;
  }
  .menu-items {
    display: flex;
    align-items: center;
  }
  .menu-items li {
    padding: 0.5rem 1rem;
    transition: background 0.3s ease-in-out;
  }
  .menu-items li:hover {
    background-color: $theme-color;
  }
  /* dropdown */
  .dropdown {
    position: relative;
  }
  .dropdown-menu {
    position: absolute;
    background: whitesmoke;
    left: 0;
    margin-top: 8%;
    opacity: 0;
    text-align: left;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .menu-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .dropdown:hover .dropdown-menu {
    top: 34px;
    opacity: 1;
    visibility: visible;
  }
  .Mobilenav {
    display: none;
  }
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
  .menu-item .sub__menus a {
    padding: 7px 0 0px 0;
    margin: 7px 27px;
  }
  .whatsAppIcon {
    position: fixed;
    bottom: 5%;
    left: 94%;
    width: 4%;
  }
  .Nav {
    position: absolute;
    bottom: 60%;
    margin-bottom: 2%;
    background: whitesmoke;
    color: #080808;
    width: 30%;
    line-height: 50px;
    padding: 8px;
    & li :hover {
      background-color: $theme-color;
    }
  }
  .dropdown:hover > .Nav {
    opacity: 1;
    bottom: 34px;
    transition: all 0.3s ease;
  }
}

/* for mobile devices */
@media (max-width: 991px) {
  .header {
    display: none;
  }
  .header__middle__logo {
    width: 80%;
  }
  .header__middle__menus {
    width: 20%;
  }
  .header__middle__logo img {
    max-width: 197px;
    margin-bottom: 6%;
    margin-top: 8%;
  }

  .main-nav .menu-item:hover > a {
    color: $theme-color !important;
  }
  .main-nav .menubar__button {
    display: block !important;
    float: right;
    background-color: #080808;
    text-align: center;
    color: #fff;
    padding: 7px 9px;
    border-radius: 8px;
    margin: auto auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
    margin-bottom: 24%;
  }
  .main-nav .menubar__button1 {
    display: block !important;
    float: right;
    text-align: center;
    color: #fff;
    padding: 7px 9px;
    border-radius: 8px;
    margin: auto auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
    margin-bottom: 24%;
  }
  .main-nav .menubar__button svg {
    font-size: 29px;
  }
  .main-nav ul.menuq2 {
    display: block !important;
    position: absolute;
    z-index: 10007;
    width: 100%;
    background-color: rgb(30 41 59);
    right: 0;
    padding-bottom: 12%;
    padding-top: 12%;
  }
  .main-nav .menu-item a {
    margin: 10px 17px;
    text-align: center;
    color: white;
  }
  .main-nav ul.main-menu {
    display: none;
  }
  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 4%;
  }
  .sub__menus__Active2 {
    display: block !important;
  }
  .menus__categorysss {
    display: none;
  }
  .menus__categorysss2 {
    display: none;
  }
  .menu-item .sub__menus__full a {
    text-align: left;
  }
  .sub__menus {
    position: relative;
    width: auto;
    background-color: #2f4f4f;
    line-height: 30px;
    padding: 14px;
  }
  .sub__menus__arrows svg,
  .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    bottom: 24%;
    left: 58%;
  }
  .whatsAppIcon {
    position: fixed;
    bottom: 10%;
    right: 4%;
    width: 14%;
  }
  .Nav {
    position: absolute;
    bottom: 100%;
    margin-bottom: 6%;
    background-color: #2f4f4f;
    color: #fff;
    width: 100%;
    line-height: 40px;
    padding: 14px;
  }
  ul.main-menu {
    display: inline-flex;
    list-style: none;
    padding: 0;
    z-index: 7;
    margin-left: 6rem;
  
    &li.menu-item:first-child {
      padding-left: 0;
      margin-left: 0;
    }
  }
  .menu-item {
    line-height: 35px;
  }
}
